import styles from './loader.module.css';
import {useOnboardingContext} from "../../../../context/onboarding-context";

export const Loader = () => {
  const {step, data} = useOnboardingContext();

  if (!data.pages[step].resources.is_compiling) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <span className={styles.loader}/>
      Profile setup...
    </div>
  )
}
